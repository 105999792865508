import { Component, OnInit } from '@angular/core';
import { OptiexService } from 'src/app/services/optiex.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  contactForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    companyName: new FormControl('', [Validators.required]),
    learnAboutUs: new FormControl('', [Validators.required]),
    interestedIn: new FormControl('', [Validators.required]),
    knowMore: new FormControl('', [Validators.required]),
  });

  constructor(
    private optiexService: OptiexService
  ) { }

  ngOnInit(): void {
  }


  sendTelegramMessage() {
    if (this.contactForm.valid) {
      var msg = "Got a query from {firstName} {lastName} \n Email: {email} \n Phone Number: {phoneNumber} \n Company Name: {companyName} \n Learn About Us: {learnAboutUs} \n Interested In: {interestedIn} \n Know More: {knowMore}"
      for (let key in this.contactForm.value) {
        msg = msg.replace(new RegExp("\\{" + key + "\\}", "gi"), this.contactForm.value[key]);
      }
      this.optiexService.send_message("SALESMANTRACKING", msg).subscribe(resp => {
        alert("Thanks for your request, We will contact you shortely...")
      })
    } else {
      alert("Please fill all fields...")
    }
  }

}
