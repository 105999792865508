<div class="container-fluid contact-us-background">
    <div class="header pt-5">
        <div class="text-center text-header">
            Contact Us
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-12 offset-md-1">
            <div class="text-header mb-3">Get in Touch</div>
            <div class="header-item"><i class="bi bi-telephone"></i> Phone Number: +91 9158812121</div>
            <div class="header-item"><i class="bi bi-envelope"></i> Email Address: sales@optiex.co.in</div>
            <div class="header-item"><i class="bi bi-house-door"></i> Registered Address: V3, Amrut Apartment, Laxmi Nagar, Nagpur, 440022, Maharashtra, India
            </div>
        </div>
        <div class="col-md-6 col-sm-12 pt-4">
            <app-contact-form></app-contact-form>
        </div>
    </div>
</div>