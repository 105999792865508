<form [formGroup]="contactForm">
    <div class="row">
        <div class="col-md-6 col-sm-12 mb-4">
            <input type="text" class="form-control" placeholder="First Name" formControlName="firstName">
        </div>
        <div class="col-md-6 col-sm-12 mb-4">
            <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName">
        </div>
        <div class="col-md-6 col-sm-12 mb-4">
            <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
        </div>
        <div class="col-md-6 col-sm-12 mb-4">
            <input type="text" class="form-control" placeholder="Phone Number" formControlName="phoneNumber">
        </div>
        <div class="col-md-6 col-sm-12 mb-4">
            <input type="text" class="form-control" placeholder="Company Name" formControlName="companyName">
        </div>
        <div class="col-md-6 col-sm-12 mb-4">
            <input type="text" class="form-control" placeholder="How did you learn about us?" formControlName="learnAboutUs">
        </div>
        <div class="col-md-12 col-sm-12 mb-4">
            <select class="form-select" aria-label="Default select example" formControlName="interestedIn">
                <option value="" disabled selected>Module Interested In...</option>
                <option value="Equipment Health monitoring">Equipment Health monitoring</option>
                <option value="Energy Module">Energy Module</option>
                <option value="Forecasting & Prediction tool">Forecasting & Prediction tool</option>
                <option value="Asset Tracking">Asset Tracking</option>
                <option value="Manpower Tracking">Manpower Tracking</option>
                <option value="Cost & Productivity Reports">Cost & Productivity Reports</option>
            </select>
        </div>
        <div class="col-md-12 col-sm-12 mb-4">
            <textarea class="form-control" rows="3" placeholder="What do you want to know more about" formControlName="knowMore"></textarea>
        </div>
        <div class="col-md-12 col-sm-12 mb-4">
            <button type="button" class="btn btn-primary" (click)="sendTelegramMessage()">Submit</button>
        </div>
    </div>
</form>