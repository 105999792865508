import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OptiexService {

  token = '425256030:AAHyvDT4i7cOzFWeceswXnuFvR0L7jijzBs'
  CHANNEL_ID = {
    "SALESMANTRACKING": -239207477
  }

  constructor(
    private http: HttpClient,
  ) { }

  get_bot_url(cmd) {
    return 'https://api.telegram.org/bot' + this.token + '/' + cmd
  }


  send_message(channel_name, message) {
    return this.http.post(
      this.get_bot_url('sendMessage'),
      {
        'chat_id': this.CHANNEL_ID[channel_name],
        'text': message,
        'parse_mode': "HTML",
      }
    )
  }
}
