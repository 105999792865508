<div class="section-container container-fluid">
    <header>
        <video [muted]="true" autoplay playsinline loop>
            <source src="assets/img/header_animation.mp4" type="video/mp4">
        </video>
        <div class="container h-100">
            <div class="d-flex h-100 text-center align-items-center">
                <div class="w-100 text-white">
                    <span class="typewriter"></span>
                </div>
            </div>
        </div>
    </header>
    <section class="ml-5 mr-5">
        <div class="row pt-5">
            <div class="col-md-3 col-sm-12 text-header text-center">
                About
            </div>
            <div class="col-md-8 col-sm-12">
                <p class="text-center">
                    <b>Optiex IOT Solutions is a one-stop solution for Digitization.</b>
                </p>
                <p class="pt-3">
                    We provide turnkey modules (Hardware - Cloud -UI) which are developed by industry experts with 30+ years of experience and is powered by cutting-edge technology. This holistic solution caters to all kinds of Industry 4.0 needs in a plugin-play manner using existing infrastructure.
                </p>
                <p class="pt-3">
                    Enabled with AI this solution can help you meet all application needs in best possible ways. People, Process and Technology are the three pillars of Optiex which helps us serve the customers with premium experience. 
                </p>
            </div>
        </div>
    </section>
    <section class="mt-5">
        <h1 class="text-center">Contact us to find out more</h1>
        <div class="row mt-5">
            <div class="col-md-3 col-sm-12"></div>
            <div class="col-md-6 col-sm-12">
                <app-contact-form></app-contact-form>
            </div>
            <div class="col-md-3 col-sm-12"></div>
        </div>
    </section>
</div>