<div class="container-fluid offering-items">
    <section class="header">
        <div class="row">
            <div class="col-md-5 col-xs-12 text-center text-header header-background">
                Offerings
            </div>
            <div class="col-md-7 col-xs-12 offering-img">
                <img src="assets/img/optiex_offering.gif" class="img-fluid" alt="" loading="lazy">
            </div>
        </div>
    </section>
    <section class="pt-4 offering">
        <div class="row pt-4">
            <div class="col-md-5 col-xs-12 offset-md-2 one">
                <div class="text-header">Asset Tracking Module</div>
                <p>This module tracks the location, movement of the material as required, Also QC Parameter, Weight,
                    Inventory management can be done. Various interlocks with production / quality departments
                    can be incorporated in the system flow.</p>
                <ul class="mt-3 mb-4">
                    <li>Raw and finished material tracking</li>
                    <li>Vehicle tracking</li>
                    <li>Uses BLE, RFID, GPS, load cells</li>
                    <li>System flow interlocks</li>
                </ul>
                <button type="button" class="btn btn-outline-primary btn-lg ms-4" (click)="scroll(contactUs)">Know
                    More</button>
            </div>
            <div class="col-md-4 col-xs-12 text-start two">
                <img src="assets/img/asset_tracking.gif" class="img-fluid" alt="" loading="lazy">
            </div>
        </div>
        <div class="row pt-4">
            <div class="col-md-4 col-xs-12 offset-md-2">
                <img src="assets/img/energy_module.gif" class="img-fluid" alt="" loading="lazy">
            </div>
            <div class="col-md-5 col-xs-12 offset-md-1">
                <div class="text-header">Energy Module</div>
                <p>Advanced Energy Module useful in getting a dashboard view, generating analytics, reports and alerts
                    for Energy and Specific Cost Optimization. It's a smart & cloud based Energy Management Solution.
                </p>
                <ul class="mt-3 mb-4">
                    <li>Easy to deploy</li>
                    <li>Accurate information & report generation</li>
                    <li>Application specific alarm</li>
                    <li>Energy cost analysis</li>
                </ul>
                <button type="button" class="btn btn-outline-primary btn-lg ms-4" (click)="scroll(contactUs)">Know
                    More</button>
            </div>
        </div>
        <div class="row pt-4">
            <div class="col-md-5 col-xs-12 offset-md-2 one">
                <div class="text-header">Equipment Health Monitoring</div>
                <p>This module enables hasslefree tracking of health & efficiency of various production & utility
                    setups. Any deviation generates dynamic alerts, alarming the key personal for taking necessary
                    actions. Al platform enables predictive maintenance after sufficient field data collection.</p>
                <ul class="mt-3 mb-4">
                    <li>KPI monitoring</li>
                    <li>Alert generation</li>
                    <li>Efficiency tracking</li>
                    <li>Predictive Maintenance</li>
                    <li>Report Extraction</li>
                </ul>
                <button type="button" class="btn btn-outline-primary btn-lg ms-4" (click)="scroll(contactUs)">Know
                    More</button>
            </div>
            <div class="col-md-4 col-xs-12 two">
                <img src="assets/img/equipment_health_monitoring.gif" class="img-fluid" alt="" loading="lazy">
            </div>
        </div>
        <div class="row pt-4">
            <div class="col-md-4 col-xs-12 offset-md-2">
                <img src="assets/img/manpower_tracking.gif" class="img-fluid" alt="" loading="lazy">
            </div>
            <div class="col-md-5 col-xs-12 offset-md-1">
                <div class="text-header">Manpower Tracking</div>
                <p>This module works on Bluetooth based hardware. The compact tracking hardware can be easily fitted
                    into ID cards, Wrist bands etc. The tracker has a long battery life of 12 months & can come with
                    required IP protection for durability.</p>
                <ul class="mt-3 mb-4">
                    <li>Real time tracking & data extraction</li>
                    <li>Alert generation, setup & modification</li>
                    <li>Seamless integration with any device</li>
                    <li>Long range of transmission</li>
                    <li>Work time / Salary report generation</li>
                </ul>
                <button type="button" class="btn btn-outline-primary btn-lg ms-4" (click)="scroll(contactUs)">Know
                    More</button>
            </div>
        </div>
        <div class="row pt-4">
            <div class="col-md-5 col-xs-12 offset-md-2 one">
                <div class="text-header">Forecasting & Prediction Tools</div>
                <p>This Domain Agnostic solution can be plugged with any database & can generate dynamic forecasting
                    with the help of previous data.</p>
                <ul class="mt-3 mb-4">
                    <li>Efficient business planning</li>
                    <li>Forecasting engine</li>
                    <li>Domain agnostic</li>
                </ul>
                <button type="button" class="btn btn-outline-primary btn-lg ms-4" (click)="scroll(contactUs)">Know
                    More</button>
            </div>
            <div class="col-md-4 col-xs-12 two">
                <img src="assets/img/forecasting_prediction_tool.gif" class="img-fluid" alt="" loading="lazy">
            </div>
        </div>
        <div class="row pt-4">
            <div class="col-md-4 col-xs-12 offset-md-2">
                <img src="assets/img/cost_productivity_module.gif" class="img-fluid" alt="" loading="lazy">
            </div>
            <div class="col-md-5 col-xs-12 offset-md-1">
                <div class="text-header">Cost & Productivity Module</div>
                <p>This Module enables easy access to Cost & Productivity reports. Various field data channels can be unified to have a holistic view. This gives a dynamic analysis of profitability & productivity.</p>
                <ul class="mt-3 mb-4">
                    <li>Cost & Productivity</li>
                    <li>Detailed cost report Productivity KPIs monitoring</li>
                    <li>Specific cost analysis</li>
                    <li>Report generation</li>
                </ul>
                <button type="button" class="btn btn-outline-primary btn-lg ms-4" (click)="scroll(contactUs)">Know
                    More</button>
            </div>
        </div>
    </section>

    <section class="pt-5" #contactUs>
        <h1 class="text-center">Contact us to find out more</h1>
        <div class="row mt-5">
            <div class="col-md-3 col-xs-12"></div>
            <div class="col-md-6 col-xs-12">
                <app-contact-form></app-contact-form>
            </div>
            <div class="col-md-3 col-xs-12"></div>
        </div>
    </section>
</div>