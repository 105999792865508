import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  dataText = ["Fetch...", "Analyze...", "Optimize..."];

  constructor() { }

  ngOnInit(): void {
    this.startTextAnimation(0);
  }

  typeWriter(text, i, fnCallback) {
    if (i < (text.length)) {
      document.querySelector(".typewriter").innerHTML = text.substring(0, i + 1) + '<span aria-hidden="true"></span>';
      var self = this;
      setTimeout(function () {
        self.typeWriter(text, i + 1, fnCallback)
      }, 100);
    }
    else if (typeof fnCallback == 'function') {
      setTimeout(fnCallback, 2000);
    }
  }

  startTextAnimation(i) {
    var self = this;
    if (this.dataText[i] == undefined) {
      setTimeout(function () {
        self.startTextAnimation(0);
      }, 2000);
    }
    if (this.dataText[i] != undefined && i < this.dataText[i].length) {
      this.typeWriter(this.dataText[i], 0, function () {
        self.startTextAnimation(i + 1);
      });
    }
  }

}
