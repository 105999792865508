<footer class="text-end section-container">
    <span class="me-4"><i class="bi bi-facebook"></i></span>
    <span class="me-4"><i class="bi bi-linkedin"></i></span>
    <span class="me-4"><i class="bi bi-instagram"></i></span>
    <span class="me-4"><i class="bi bi-whatsapp"></i></span>
</footer>
<nav class="navbar navbar-expand-lg navbar-light bg-light pt-0 pb-0">
    <div class="container-fluid">
        <div class="d-flex flex-grow-1">
            <span class="w-100 d-lg-none d-block">
            </span>
            <a class="navbar-brand d-none d-lg-inline-block pt-0 pb-0" routerLink="/">
                <img src="/assets/img/optiex-logo.png" alt="" width="213" height="94">
            </a>
            <a class="navbar-brand-two d-lg-none d-inline-block" routerLink="/">
                <img src="/assets/img/optiex-logo.png" alt="logo" width="200" height="90">
            </a>
            <div class="w-100 text-end mt-4">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#myNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </div>
        <div class="collapse navbar-collapse flex-grow-1 text-right" id="myNavbar">
            <ul class="navbar-nav ms-auto flex-nowrap">
                <li class="nav-item">
                    <a routerLink="/" class="nav-link m-2 menu-item nav-active">Home</a>
                </li>
                <li class="nav-item">
                    <a routerLink="offerings" class="nav-link m-2 menu-item">Offerings</a>
                </li>
                <li class="nav-item">
                    <a routerLink="contact" class="nav-link m-2 menu-item">Contact</a>
                </li>
            </ul>
        </div>
    </div>
</nav>